<template>
    <h1>Events for Good</h1>
    <div class="events">
        <EventCard v-for="event in events" :key="event.id" :event="event"/>
    </div>
</template>

<script>

import EventCard from '@/components/EventCard'
import EventService from '@/services/EventService.js'

export default {
    name: 'EventsList',
    components: {
        EventCard
    },

    data(){
        return {
            events: null,
        }
    }, 

    created() {
        EventService.getEvents()
            .then(response => {
                this.events = response.data
            })
            .catch(error => {
                console.log(error)
            })
    }
}
</script>

<style scoped>
    .events{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
</style>
